<template>
	<div class="page project new-project fade-in">
		<div class="centered-content">
			<transition appear>
				<content-modal
          v-if="currentButton"
          @close="closeProjectButtonContent"
          :showBackButton="false"
					:class="{ 'full': currentButtonType === 'gallery', 'tabs': currentButton.tabs && currentButton.tabs.length > 1 }"
					:style="{ paddingBottom: `${mobileButtonsHeight}px` }"
        >
					<template v-if="showProjectContent && !showCalcultor" #header>
						<transition appear>
							<div class="contextWrapper">
								<div v-if="!isMobile" class="headerBackgroundText gradientText">
									ABOUT
								</div>
								<div v-if="useInnerTitle" class="projectHeader">{{ project.name }}</div>
								<div class="project-content" v-html="project.content"></div>
								<span v-if="currentButtonType !== 'gallery'">{{ currentButton.label }}</span>
							</div>
						</transition>
					</template>
					<div v-if="showCalcultor" class="calculator-wrapper">
						<div v-if="!isMobile" class="headerBackgroundText gradientText">
							INVESTMENT
						</div>
						<FpCalculator :initialData="currentButton.content"/>
					</div>
					<EntrepreneursComp v-else-if="showEntrepeneurContent"
						:items="currentButton.tabs[currentContentTabIndex].content" :header="entrepreneursHeader" />
					<building-specs v-else-if="currentButtonType === 'specifications'"
						:content="currentButton.tabs[currentContentTabIndex]" :viewType="isMobile ? 'mobile' : 'big'" />
					<transition appear v-else-if="currentButtonType === 'gallery'">
						<div v-if="oneBigImage" class="the-big-picture">
							<div class="ps-gal"
								:class="[`type-${oneBigImage.type}`, `img-${oneBigImage.image_layout}`]">
								<a :href="oneBigImage.url" :data-pswp-width="oneBigImage.width"
									:data-pswp-height="oneBigImage.height" target="_blank" rel="noreferrer">
									<img :src="oneBigImage.thumbnail" :alt="oneBigImage.alt">
								</a>
							</div>
						</div>
						<div v-else class="gallery-slides">
							<div v-if="currentContentTab">
								<template v-if="currentContentTab.acf_fc_layout === 'gallery'">
									<masonry-gallery v-if="currentContentTab && currentContentTab.type === 'masonry'"
										:images="currentContentTab.gallery"
										:items-per-row="isMobile ? currentContentTab.mobile_items_per_row : currentContentTab.items_per_row"></masonry-gallery>
									<slider-gallery v-else class="horizontalSlider" :show-disclaimer="false"
										:slides="currentContentTab.gallery" :height="isMobile ? '20rem' : '30rem'"
										:showDots="false">
										<template v-slot="{ slide }">
											<div v-if="currentContentTabClickAction === 'edit' && !isMobile"
												:class="`img-${slide.image_layout} image-edit`"
												@click="$store.dispatch('openImageEditor', slide.url)" tabindex="1">
												<img :src="slide.url" :alt="slide.alt">
												<click-here></click-here>
											</div>
											<div v-else-if="slide.type === 'image'" class="ps-gal"
												:class="[`type-${slide.type}`, `img-${slide.image_layout}`]">
												<a :href="slide.url" :data-pswp-width="slide.width"
													:data-pswp-height="slide.height" target="_blank" rel="noreferrer">
													<img :src="slide.thumbnail" :alt="slide.alt">
													<illustration-only-disclaimer></illustration-only-disclaimer>
												</a>
											</div>
											<div v-else :class="[`type-${slide.type}`, `img-${slide.image_layout}`]">
												<video :src="slide.url" controls></video>
											</div>
										</template>
									</slider-gallery>
								</template>
								<div class="iframe" v-else-if="currentContentTab.acf_fc_layout === 'iframe'"
									:class="{ 'youtubeIframe': currentContentTab.src.includes('youtube') }">
									<iframe v-if="currentContentTab.src" :src="currentContentTab.src"></iframe>
								</div>
								<masonry-gallery v-else-if="currentContentTab.acf_fc_layout === 'masonry'"
									:images="currentContentTab.gallery"
									:items-per-row="isMobile ? currentContentTab.mobile_items_per_row : currentContentTab.items_per_row"></masonry-gallery>
							</div>
						</div>
					</transition>
					<div class="flipbook-wrapper" v-else-if="currentButtonType === 'flipbook'">
						<fp-flip-book :pages="currentButton.images" :sizes="currentButton.images_sizes"
							:fallback-sizes="{ width: currentButton.width, height: currentButton.height }"></fp-flip-book>
					</div>
					<!-- <div class="calculator-wrapper" v-else-if="currentButtonType === 'roi_calculator'">
						<Calculator :content="currentButton"></Calculator>
						<pre>{{currentButton}}</pre>
					</div> -->
					<!-- <template #footer>
													<nav
															v-if="currentButton.tabs_layout === 'bottom_buttons' && currentButton.tabs && currentButton.tabs.length > 1"
															class="footer-tabs"
													>
															<fp-button
																	v-for="(tab, tabIndex) in currentButton.tabs"
																	:key="tabIndex"
																	:class="{active: currentContentTabIndex === tabIndex}"
																	@click="changeContentTab(tabIndex)"
															>{{tab.label}}</fp-button>
													</nav>
											</template> -->
				</content-modal>
			</transition>
			<transition appear>
				<project-modal v-if="projectModal" @button-clicked="projectButtonClicked"
					@subMenu-clicked="changeContentTab" :current-button="currentButton"
					:currentSubMenuIndex="currentContentTabIndex" :project="project"></project-modal>
			</transition>
		</div>
	</div>
</template>

<script>
import ProjectModal from "@/components/ProjectModal"
import SliderGallery from "@/components/SliderGallery"
import ContentModal from "@/components/ContentModal"
import EntrepreneursComp from "@/components/Entrepreneurs.vue";
import { mapGetters } from "vuex"
import { delay } from "@/utils"
import BuildingSpecs from "@/components/BuildingSpecs"
import FpFlipBook from "@/components/FpFlipBook"
import PhotoSwipeLightbox from "photoswipe/lightbox"
import 'photoswipe/style.css'
import MasonryGallery from "@/components/MasonryGallery"
import ClickHere from "@/components/ClickHere.vue";
import FpCalculator from "@/components/Calculator/Calculator.vue";
import IllustrationOnlyDisclaimer from "@/components/IllustrationOnlyDisclaimer.vue";


export default {
	name: "ProjectPage",
	components: {
		IllustrationOnlyDisclaimer,
		ClickHere,
		EntrepreneursComp,
		MasonryGallery,
		FpFlipBook,
		BuildingSpecs,
		ContentModal,
		SliderGallery,
		ProjectModal,
		FpCalculator
	},
	data() {
		return {
			currentButton: null,
			currentContentTabIndex: 0,
			lightbox: null,
			
		}
	},
	computed: {
		currentButtonType() {
			return this.currentButton && this.currentButton.acf_fc_layout ? this.currentButton.acf_fc_layout : null
		},
		project() {
			return this.$store.getters.currentProject
		},
		currentContentTab() {
			if (this.currentButton &&
				this.currentButton.tabs &&
				this.currentButton.tabs[this.currentContentTabIndex] &&
				this.currentButton.tabs[this.currentContentTabIndex].content &&
				this.currentButton.tabs[this.currentContentTabIndex].content[0])
				return this.currentButton.tabs[this.currentContentTabIndex].content[0]
			return null
		},
		showCalcultor() {
			return this.currentButtonType === 'roi_calculator'
		},
		currentContentTabClickAction() {
			if (this.currentContentTab?.click_action) {
				return this.currentContentTab.click_action
			}
			return null
		},
		showProjectContent() {
			const firstButton = this.project && this.project.buttons && this.project.buttons[0] ? this.project.buttons[0] : null;
			return this.currentButton && this.currentButton.label && (firstButton && this.currentButton.label === firstButton.label) && this.currentContentTabIndex === 0 && this.currentButton.tabs_layout === 'top_tabs'
		},
		showEntrepeneurContent() {

			const currentTab = this.currentButton && this.currentButton.tabs && this.currentButton.tabs[this.currentContentTabIndex] ? this.currentButton.tabs[this.currentContentTabIndex] : null;
			// check if currentTab.content is array and has one of them with currentTab.content[0].acf_fc_layout === 'gallery'
			const isContent = currentTab && currentTab.content && currentTab.content.length > 0 && currentTab.content.some(item => item.acf_fc_layout === 'content');

			return this.currentButton && this.currentButton.label && this.currentButton.tabs && isContent;
		},
		entrepreneursHeader() {
			return this.currentButton.tabs[this.currentContentTabIndex].label === 'יזם' || this.currentButton.tabs[this.currentContentTabIndex].label === 'Ent' ? 'ENTREPRENEURS' : 'ARCHITECTS';
		},
		oneBigImage() {
			if (!this.showEntrepeneurContent && !this.showProjectContent && this.currentButton && this.currentButton.tabs) {
				if (this.currentButton.tabs.length === 1 && this.currentContentTab && this.currentContentTab.acf_fc_layout === 'gallery' && this.currentContentTab.gallery && this.currentContentTab.gallery.length === 1) {
					return this.currentContentTab.gallery[0];
				}
			}
			return false;
		},
		useInnerTitle() {
			if (this.$store.getters.settings && this.$store.getters.settings.projects && this.$store.getters.settings.projects.use_inner_title !== undefined) {
				return this.$store.getters.settings.projects.use_inner_title
			}
			return true
		},
		...mapGetters(['projectModal', 'mobileButtonsHeight', 'hasWhatsappButton', 'showFloatingContactButton', 'isMobile', 'bottomBarHeight'])
	},
	watch: {
		projectModal(newVal) {
			if (!newVal) {
				this.closeProjectButtonContent()
			}
		}
	},
	methods: {
		changeContentTab(tabIndex) {
			this.currentContentTabIndex = tabIndex

			this.destroyPs()
			this.$nextTick().then(() => {
				this.initPs()
			})
		},
		loadProject() {
			/*const id = to.params.projectId ? parseInt(`${to.params.projectId}`, 10) : null
			this.$store.commit('selectProject', id)*/
			this.$store.commit('toggleProjectModal', true)
		},
		async projectButtonClicked(button) {
			if (this.currentButton) {
				if (button.label !== this.currentButton.label) {
					this.currentContentTabIndex = 0

					if (this.currentButton) {
						this.currentButton = null
						await delay(500)
					}

					this.currentButton = button

					this.destroyPs()
					this.$nextTick().then(() => {
						this.initPs()
					})

				}

			} else {
				this.currentContentTabIndex = 0

				if (this.currentButton) {
					this.currentButton = null
					await delay(500)
				}

				this.currentButton = button

				this.destroyPs()
				this.$nextTick().then(() => {
					this.initPs()
				})
			}
			console.log("this.currentButton", this.currentButton)
		},
		closeProjectButtonContent() {
			this.currentButton = null
		},
		initPs() {
			if (this.currentContentTabClickAction !== 'edit' || this.isMobile) {
				if (!this.lightbox) {
					if (this.oneBigImage) {
						this.lightbox = new PhotoSwipeLightbox({
							gallery: '.the-big-picture',
							children: '.ps-gal a',
							pswpModule: () => import('photoswipe'),
						});
						this.lightbox.init();
					} else {
						this.lightbox = new PhotoSwipeLightbox({
							gallery: '.gallery-slides',
							children: '.ps-gal a',
							pswpModule: () => import('photoswipe'),
						});
						this.lightbox.init();
					}
				}
			}
		},
		destroyPs() {
			if (this.lightbox) {
				this.lightbox.destroy();
				this.lightbox = null;
			}
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.loadProject()
		})
	},
	beforeRouteUpdate() {
		this.loadProject()
	}
}
</script>
<style scoped lang="scss">
.page.project {
	background-color: black;

	.project-content {
		position: relative;
		z-index: 2;
		font-size: 1.125rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.25rem;

	}
}

.projectHeader {
	font-size: 4rem;
	margin-bottom: 1.5rem;
	border-radius: 0.3125rem;
	position: relative;
	z-index: 7;


}

.the-big-picture {
	a {
		display: block;
		text-align: center;
	}

	.img-contain img {
		object-fit: contain;
		max-height: calc(100svh - 81px);
		object-position: center;
	}

	.img-cover img {
		object-fit: cover;
		max-height: calc(100svh - 81px);
		object-position: center;
	}
}

.horizontalSlider {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;

	&.v-enter-active,
	&.v-leave-active {
		transition: opacity .8s ease;
	}

	&.v-enter-from,
	&.v-leave-to {
		opacity: 0;
	}

	:deep(.swiper-wrapper) {
		align-items: flex-end !important;

		.swiper-slide {
			margin-left: 14px !important;
		}

		.swiper-slide img {
			border-radius: 0.3125rem;


		}

	}

	.swiper-wrapper {}
}

.headerBackgroundText {
	position: absolute;
	left: -2rem;
	top: 01rem;


}

.project-modal {
	position: sticky;
	bottom: 0;
	transition: .8s all ease;
	opacity: 1;
	z-index: 5;

	&.v-enter-active,
	&.v-leave-active {
		transition: opacity .8s ease;
	}

	&.v-enter-from,
	&.v-leave-to {
		opacity: 0;
	}
}

.content-modal {

	height: 89vh;

	.contextWrapper {

		max-width: 60%;
		padding-top: 5rem !important;
		padding-right: 5rem !important;

		&.v-enter-active,
		&.v-leave-active {
			transition: opacity .8s ease;
		}

		&.v-enter-from,
		&.v-leave-to {
			opacity: 0;
		}
	}

	.gallery-slides {

		&.v-enter-active,
		&.v-leave-active {
			transition: opacity .8s ease;
		}

		&.v-enter-from,
		&.v-leave-to {
			opacity: 0;
		}
	}

	&.v-enter-active,
	&.v-leave-active {
		transition: opacity .8s ease;
	}

	&.v-enter-from,
	&.v-leave-to {
		opacity: 0;
	}

	:deep(.content-modal-header) {


		&.tabs {
			background-color: #000;
			color: #fff;
		}

		nav.header-tabs {
			flex-grow: 1;

			ul {
				display: flex;
			}

			ul button {
				color: #fff;
				font-size: 18px;
				margin: 0 6px;

				&.active {
					color: var(--primary-light);
				}
			}
		}
	}

	:deep(nav.footer-tabs) {
		display: flex;
		gap: 15px;
		margin-bottom: 15px;
		z-index: 10;
		overflow-x: auto;
		margin-right: 10px;
		margin-left: 10px;

		.btn {
			background-color: #000;

			&:first-child {
				margin-right: auto;
			}

			&:last-child {
				margin-left: auto;
			}
		}
	}
}

.project-current-button {
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.86) 0%, #000000 100%);
	border: 1px solid #FFFFFF;
	box-sizing: border-box;
	border-radius: 5px;
	position: relative;
	flex-grow: 1;
	min-height: 70vh;
	max-width: calc(100vw - 470px - 15px - (2.6875rem * 2));

	&.v-enter-active,
	&.v-leave-active {
		transition: transform 1s ease;
	}

	&.v-enter-from,
	&.v-leave-to {
		transform: translateX(-100vw);
	}

	.iframe {
		position: absolute !important;
		top: 0;
		width: 100%;
		height: 100%;
		min-height: calc(70vh - (1.875rem + 4.375rem));
	}

	.project-current-button-header {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;
		padding-right: 1.875rem;
		padding-left: 1.875rem;
		border-bottom: solid 1px #fff;
		max-width: 100%;
		min-height: 50px;
		flex-grow: 1;
	}

	.project-current-button-content {
		padding: 1rem 1.875rem 1.875rem;
	}

	&.full {
		.project-current-button-header {
			display: none;
		}

		.project-current-button-content {
			padding: 0;
		}
	}

	.project-current-button-header-inner {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		max-width: 100%;

		.project-current-button-title {
			flex-grow: 0;
		}

		ul.iframes-tabs {
			display: flex;
			flex-flow: row nowrap;

			li {
				padding: 1rem 3rem;
				white-space: nowrap;
			}
		}

		flex-grow: 1;
	}

	button.close-btn {
		flex-grow: 0;
		margin-top: 3px;
	}
}

.calculator-wrapper {
	.gradientText {
		background: linear-gradient(0deg, rgba(255,255,255,0.1), rgba(0,0,0,0.2));
		font-size: 12rem;
		left: 12rem;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

}
.is-mobile {
	.page.project {
		padding: 0;
		flex-flow: column;

		.centered-content {
			flex-flow: column;
			gap: 0;
		}

    .horizontalSlider {
      position: relative;
    }
	}

	.content-modal :deep(.content-modal-header) {
		white-space: nowrap;
		max-width: 100vw;
		overflow-x: auto;
    margin-bottom: 15px;

		.contextWrapper {

			max-width: 100%;

			padding-top: 4rem !important;
			padding-right: 1rem !important;
			padding-left: 1rem !important;
			white-space: normal;

			.projectHeader {
				font-size: 1.2rem;
				margin-bottom: 1.5rem;
				border-radius: 0;
				position: relative;
				z-index: 7;


			}

			.project-content {
				max-height: 36svh;
				overflow-y: auto;
				font-size: .9rem;
				font-style: normal;
				font-weight: 400;
				line-height: 1rem;
			}
		}

	}

	.project-current-button.full {
		.project-current-button-header {
			display: flex;
			border-bottom: none;
		}
	}

	.project-modal {
		//width: 100%;
		//max-width: 100vw;
		padding: 0;
		border-radius: 0;
		border: none;
		background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, .81) 43.75%, rgba(0, 0, 0, 0.50) 55.13%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%);
	}


}

.fp-side-menu-wrapper+.fp-app-wrapper {
	.page.project {
		padding: 0;
		align-items: stretch;


		:deep(.content-modal) {
			border: none;
			border-radius: 0;
			max-width: none;
		}
	}

	.project-content {
		-webkit-column-count: 2;
		/* Chrome, Safari, Opera */
		-moz-column-count: 2;
		/* Firefox */
		column-count: 2;

		-webkit-column-gap: 40px;
		/* Chrome, Safari, Opera */
		-moz-column-gap: 40px;
		/* Firefox */
		column-gap: 40px;

		-webkit-column-rule: 1px solid black;
		/* Chrome, Safari, Opera */
		-moz-column-rule: 1px solid black;
		/* Firefox */
		column-rule: 1px solid black;
	}
}

.ltr {
	.project-modal {

		&.v-enter-from,
		&.v-leave-to {
			transform: translateX(-600px);
		}
	}

	.headerBackgroundText {
		left: 22rem;
	}

	.content-modal {
		.contextWrapper {

			padding-left: 7rem !important;
		}

		&.v-enter-from,
		&.v-leave-to {
			transform: translateX(100vw);
		}

		:deep(nav.footer-tabs) {
			.btn {
				background-color: #000;

				&:first-child {
					margin-right: 10px;
					margin-left: auto;
				}

				&:last-child {
					margin-left: 10px;
					margin-right: auto;
				}
			}
		}
	}
}

@media only screen and (max-height: 740px) and (min-width: 770px) {
	.contextWrapper {

		max-width: 100% !important;
	}

	.project-content {
		-webkit-column-count: 3 !important;
		/* Chrome, Safari, Opera */
		-moz-column-count: 3 !important;
		/* Firefox */
		column-count: 3 !important;
	}
}
</style>

<template>

      <Slider
          class="fullWidth"
          v-model="sliderValue"
          :format="format"
          :step="step"
          @change="updateValue"
      />

</template>

<script>
import Slider from '@vueform/slider'

export default {
    components: { Slider },
    props: {
        value: {
            type: Array,
            default: function () {
                return [1, 100]
            },
        },
        step: {
            type: Number,
            default: 1
        },
        format: {
            type: Function,
            default: function (value) {
                // console.log('value', value)
                return `${value}`
            }
        },
        // min: {
        //     default: 0
        // },
        // max: {
        //     default: 100
        // }
    },
    data() {
        return {
            sliderValue: this.value,
            // format: function (value) {
            //     return `€${value}`
            // }

        };
    },
    methods: {
        updateValue(value) {
            this.$emit('update:value', value);
        }
    },
    watch: {
        value: function (newVal) {
            console.log('Slider newVal', newVal)
            this.sliderValue = newVal;
        }
    }
}
</script>
<style src="@vueform/slider/themes/default.css"></style>
<template>
    <transition appear>
        <div class="contact-modal" v-if="show" :class="{'apartment-only': apartmentOnly, 'has-bottom-bar': hasBottomBar && !contact_bar_static}">
            <close-btn v-if="(!contact_bar_static && isDesktop) || isMobile || isTablet" @click="close"></close-btn>
            <main-logo></main-logo>
            <form @submit.prevent="send" ref="contactForm">
                <p class="form-title">{{$t('אנחנו כאן לשירותכם')}}</p>
                <template v-if="fields">
                    <fp-input
                        v-for="(field, fieldIndex) in fields"
                        :key="fieldIndex"
                        v-model="formData[field.name]"
                        :name="field.name"
                        :type="field.type"
                        :placeholder="field.label"
                        :disabled="loading"
                        :required="field.required"
                    ></fp-input>
                </template>
                <template v-else>
                    <fp-input v-model="formData['name']" name="name" :disabled="loading" :placeholder="$t('שם')" required></fp-input>
                    <fp-input v-model="formData['phone']" name="phone" :disabled="loading" :placeholder="$t('טלפון')" required></fp-input>
                </template>
                <div v-if="terms_link && terms_link.url" class="checkbox-wrapper">
                        <fp-checkbox v-model="formData.terms_approval" name="terms_approval" :inline="true">{{$t('אישור')}}</fp-checkbox>
                        <router-link :target="terms_link.target" :to="{name: 'content', params: {postName: 'תקנון'}}">{{ $t('תקנון') }}</router-link>
                </div>
                
                <!-- <fp-button type="submit" class="submit-btn" :disabled="loading" variant="primary-outline">{{$t('שלח')}}</fp-button> -->
                <button
                  v-if="!loading && !alertMessage"
                  class="sendButtonWrapper"
                  :style="{
                    width: sendButtonSize,
                    height: sendButtonSize,
                  }"
                  :disabled="loading"
                  type="submit"
                >
                    <AnimatedCircles :animate="loading" :color="colors.primary"/>
                    <!-- <FpNewIcon v-else iconName="fpBeautifullCircle" class="fpBeautifullCircle" size="10rem"/> -->
                    <span :class="{disabled: loading}">{{$t('שלח')}}</span>
                </button>
                <div class="alert-message">
                  <loading-data v-if="loading">{{$t('שולח')}}</loading-data>
                  <template v-else-if="alertMessage">
                    <p>{{alertMessage}}</p>
                    <button class="reset-btn" type="button" @click="formReset">{{$t('איפוס')}}</button>
                  </template>
                </div>
                <div class="bottomSection">
                    <img src='@/assets/map.png' />
                </div>
            </form>
            <div v-if="contact_details && (contact_details.phone || (contact_details.whatsapp && contact_details.whatsapp.phone))" class="contact-links">
                <tel-link
                    v-if="contact_details.phone"
                    :tel="contact_details.phone"
                ></tel-link>
                <span v-if="contact_details.phone && contact_details.whatsapp && contact_details.whatsapp.phone" class="divider"> | </span>
                <tel-link
                    v-if="contact_details.whatsapp && contact_details.whatsapp.phone"
                    type="whatsapp" :tel="contact_details.whatsapp.phone"
                    :message="contact_details.whatsapp.message"
                ></tel-link>
            </div>
						<!--<div v-else class="contact-links">
                <tel-link tel="*6494"></tel-link>
                <span class="divider"> | </span>
                <tel-link type="whatsapp" tel="972507125452" message="I'm interested in info"></tel-link>
            </div>-->
        </div>
    </transition>
</template>

<script>
import CloseBtn from "@/components/buttons/CloseBtn";
// import FpButton from "@/components/buttons/FpButton";
import FpInput from "@/components/FpInput";
import TelLink from "@/components/TelLink";
// import AnimatedCircles from "@/components/AnimatedCircles";
import {mapGetters} from "vuex";
import LoadingData from "@/components/LoadingData";
import FpCheckbox from "@/components/buttons/FpCheckbox";
// import FpNewIcon from "@/components/buttons/FpNewIcon";
import AnimatedCircles from "@/components/AnimatedCircles.vue"
import MainLogo from "@/components/MainLogo";

export default {
    name: "ContactModal",
    components: {
        FpCheckbox, 
        LoadingData, 
        TelLink, 
        CloseBtn, 
        AnimatedCircles,
        // FpButton, 
        FpInput, 
        // FpNewIcon,
        MainLogo
        },
    computed: {
        show(){
            return this.$store.getters.contactModal
        },
        fields(){
            if(this.$store.getters.settings && this.$store.getters.settings.contact_form && this.$store.getters.settings.contact_form.fields){
                return this.$store.getters.settings.contact_form.fields
            }
            return null
        },
        contact_details(){

            console.log(this.settings && this.settings.contact_form && this.settings.contact_form.contact_details)
            if(this.settings && this.settings.contact_form && this.settings.contact_form.contact_details){
                console.log("this.settings.contact_form.contact_details", this.settings.contact_form.contact_details)
                return this.settings.contact_form.contact_details
            }
            return null
        },
        conversion_label(){
                if(this.settings && this.settings.contact_form && this.settings.contact_form.contact_conversion_label){
                        return this.settings.contact_form.contact_conversion_label
                }
                return null
        },
        contact_bar_static(){
                if(this.settings && this.settings.contact_form && this.settings.contact_form.contact_bar_static){
                        return this.settings.contact_form.contact_bar_static
                }
                return false
        },
        terms_link(){
                if(this.settings && this.settings.contact_form && this.settings.contact_form.terms_link){
                        return this.settings.contact_form.terms_link
                }
                return null
        },
        terms_approval_mandatory(){
                if(this.settings && this.settings.contact_form && this.settings.contact_form.terms_approval_mandatory){
                        return this.settings.contact_form.terms_approval_mandatory
                }
                return null
        },
        ...mapGetters(['isMobile','isTablet','isDesktop','settings', 'apartmentOnly', 'hasBottomBar', 'colors'])
    },
    data(){
        return {
            formData: {
                    terms_approval: false
            },
            alertMessage: null,
            loading: false,
            sendButtonSize: '120px'
        }
    },
    methods: {
        async send(){
            if(this.terms_approval_mandatory && this.terms_link && this.terms_link.url && !this.formData.terms_approval){
                    alert(this.$t('יש לאשר את התקנון'))
                    return
            }
            this.formData.href = window.location.href
            this.loading = true
            const {data, status} = await this.$http.post('contact', this.formData)
            this.loading = false
            if([200, 201].includes(status)){
								if(this.conversion_label && typeof(window.gtag) === 'function'){
										window.gtag('event', 'conversion', {
												'send_to': this.conversion_label
										});
								}
								if(typeof(window.dataLayer) !== 'undefined'){
										window.dataLayer.push({'event':'lead'});
								}
                this.formReset()
                this.alertMessage = this.$t('פרטיך התקבלו בהצלחה!');
                if(!data.emailSent){
                  console.warn('Email was not sent!')
                }
            }
        },
        close(){
            this.$store.commit('toggleContactModal', false)
        },
        formReset(){
          this.alertMessage = null;
            this.formData = {
              terms_approval: false
            }
            if(this.fields){
                this.fields.forEach((field) => {
                    this.formData[field.name] = "";
                })
                this.$refs.contactForm.reset()
            }
        }
    },

}
</script>

<style scoped lang="scss">
    .contact-modal {
        padding: 1.534375rem;
        /*background-color: rgba(0, 0, 0, 0.8);*/
        background-color: rgba(31, 33, 35, 1);
        min-height: 5.1875rem;
        box-sizing: border-box;
        border-radius: 0.375rem;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.69);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        transform: translateX(0);
        z-index: 15;
        overflow: hidden;
        width: 25rem;

				&.apartment-only {
						bottom: 0;
				}

        &.v-enter-active,
        &.v-leave-active {
            transition: transform .3s ease;
        }

        &.v-enter-from,
        &.v-leave-to {
            transform: translateX(200%);
        }
        .form-group {
            width: 100%;
            margin-top: 1.5rem;
        }
        .bottomSection img{
            transform: translate(6.5rem, 2rem);
            scale: 2;
        }
        .reset-btn {
          font-size: 0.875rem;
          color: #fff;
          border: none;
          background-color: transparent;
        }
        .alert-message {
            min-height: 150px;
            display: flex;
            align-items: center;
            padding: 15px;
            text-align: center;
            float: left;
            :deep(.loading-data){
                padding: 0;
            }
        }
        .sendButtonWrapper{
            margin-top: 2rem;
            float: left;
            position: relative;
            transform: translateX(20px);
            cursor: pointer;
            font-family: var(--primary-font),serif;
            color: #fff;
            font-size: 1.25rem;
        }
        .sendButtonWrapper span{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            
        }
        .fpBeautifullCircle{
            position: absolute;
            top: 0;
            left: 0
        }
        form {
            position: absolute;
            top: 19rem;
        }

        .form-title {
            font-size: 1.5625rem;
            font-weight: 700;
            line-height: 1;
            margin: 0;
        }

        .contact-links {
            display: flex;
            flex-flow: row nowrap;
            gap: 1.25rem;
            align-items: center;
        }

        .tel-link {
            font-size: 1.875rem;
            font-weight: 700;
        }

        .divider {
            font-size: 1.875rem;
        }

   
       }

    .is-desktop {
        .contact-modal{
            &.middle-modal {
                flex-flow: column;
                align-items: flex-start;
                width: auto;
                bottom: auto;
                top: 50%;
                transform: translate(-50%, -50%);
                padding-bottom: 2rem;

                &.v-enter-active,
                &.v-leave-active {
                    transition: transform .3s ease;
                }

                &.v-enter-from,
                &.v-leave-to {
                    transform: translate(-50%, 100%);
                }

                form {
                    flex-flow: column;
                    align-items: stretch;

                    .submit-btn {
                        align-self: center;

              
                    }

                    :deep(.form-group) {
                        input {
                            width: 100%;
                        }
                    }
                }

                .contact-links {
                    margin-top: 20px;
                    align-self: center;
                    .divider {
                        display: none;
                    }
                }
            }

            :deep(.main-logo) {
              display: block;
              text-align: center;
              img.logo-img {
                  max-height: 250px;
              }
            }

        }
    }

    .is-mobile {
        .contact-modal {
            width: 100vw;
            z-index: 22;
            :deep(.close-btn) {
                position: absolute;
                top: 5rem;
                right: 1rem;
                
            }
            :deep(.main-logo) {
                display: none;
                img.logo-img {
                    max-height: 100px;
                    margin-top: 4rem !important;
                }

            }
        }
        .sendButtonWrapper{
            margin-top: 2rem;
        }
    }

    .ltr {
        .contact-modal {
            left: 0;
            &.v-enter-from,
            &.v-leave-to {
                transform: translateX(-200%);
            }
        }
        .sendButtonWrapper{
            float: right;
            margin-right: 3rem;
        }
    }

		/*.fp-side-menu-wrapper + .fp-app-wrapper {
				.contact-modal {
						border-radius: 0;
						box-shadow: none;
						//background-color: rgba(0, 0, 0, 0.60);
						backdrop-filter: blur(5px);
						background-color: rgba(0, 0, 0, 0.8);

						&.v-enter-from,
						&.v-leave-to {
								transform: translateX(-160%);
						}

						.form-title {
								font-weight: 500;
						}
				}
		}*/
</style>

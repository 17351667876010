<template>
  <div class="chart-container">  
      <apexchart 
        type="pie" 
        :options="chartOptions" 
        :series="expenses"
        @dataPointSelection="clickHandler"></apexchart>

  </div>
  </template>
  
  <script>
  
  import { formatNumber } from "@/utils"
  export default {
    name: 'ExpensesChart',
    components: {
      //   apexchart: VueApexCharts,
    },
    props: {
      expenses: {
        type: Array,
        default: () => []
      }
      // yearlyMaintenance: {
      //   type: Object,
      //   default: () => {
      //     return {
      //       3: 100000,
      //       4: 120000
      //     }
      //   }
      // },
      // managementFeesPercent: {
      //   type: Object,
      //   default: () => {
      //     return {
      //       shortTerm: 0.3,
      //       longTerm: 0.2
      //     }
      //   }
      // },

    },
    data() {
      return {
          chartOptions: {
            chart: {
              // width: "100%",
              height: '500px',
              // events: {
              //   click: function(event, chartContext, opts) {
              //     console.log(event, chartContext, opts)
              //     // The last parameter opts contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
              //   }
              // },
              type: 'pie',
  
            },
            plotOptions: {
              pie: {
                expandOnClick: true
              }
            },
            labels: [
              'Authorised fees', 
              'Maintenance fees', 
              // 'Maintenance Fees- lease period', 
              'Management fees', 
              // 'Management fees - rental route-Long term rental Yearly'
            ],
            theme: {
              monochrome: {
                enabled: true
              }
            },
            legend: {
              show: false, // Ensure the legend is displayed
              position: 'bottom', // Position the legend at the bottom
              formatter: function(seriesName, opts) {
                return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex], "&#3647"].join('');
              },
              labels: {
                colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'], // Set the text color of the legend items to white
              },
              markers: {
                width: 10,
                height: 10,
              },
              itemMargin: {
                vertical: 5 // Adjust the vertical margin between legend items
              },
  
            },
            responsive: [{
              // breakpoint: 480,
              options: {
                chart: {
                  // width: 250,
                  // height: 500
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
        }
    }, 
    computed: {

      totalExpenses() {
        return this.expenses.reduce((a, b) => a + b, 0)
      },

    },
    watch: {
      totalExpenses: {
        handler: function (val) {
          this.$emit('onExpensesChanged', val)
        },
        deep: true
      }
    },
    mounted() {
      this.$emit('onExpensesChanged', this.totalExpenses)
    },
    methods: {
      formatNumber(num) {
      return formatNumber(num)
      },
      clickHandler(event, chartContext, config){
        console.log("click")
        console.log(event)
        console.log(chartContext)
        console.log(config)
        console.log(this.expenses[config.dataPointIndex]);

    },
    }
  }
  </script>
  
  <style>

  .apexcharts-legend {
    display: block !important;

  }
  .apexcharts-legend-series {
    display: block !important;

  }
  </style>
<template>
  <div class="wrapper">
    <div class="header ltr">
      <div class="header-text">Investment</div>
      <div class="header-amount">{{ formatNum(defaultData.initialInvestment[selectedVila]) }};</div>

    </div>
    <div class="row">
      <div :class="showAdmin ? 'col-2' : 'col-3'">
        <div class="expensis ltr itemWrapper">
          <ExpensesChart 
            :expenses="calculatedExpenses"
            @onExpensesChanged="handleExpensesChange" />
          <div>
          <ul class="details p-1">
            <li v-for="(expense, index) in defaultData.expenses" :key="index" class="flex between">
              <span>{{expense.label}}</span>
              <span>{{formatNum(calculatedExpenses[index])}};</span>
            </li>

          </ul>
          </div>
        </div>
      </div>
      <div :class="showAdmin ? 'col-6' : 'col-9'">
        <div class="row">
          <div class="col-8">
            <div class="topSection itemWrapper">
              <div class="rooms ltr flex between">
                <span>ROOMS</span>
                <fp-custom-drop-down :value="getDropDownLabelByValue(selectedCurrency, defaultData.currencies)" 
                  :options="defaultData.currencies" :empty-option="false" @change="setSelectedCurrency" />
              </div>
              <div class="dropDowns">
                <fp-custom-drop-down :value="getDropDownLabelByValue(selectedVila, defaultData.vilaTypes)" 
                  :options="defaultData.vilaTypes" :empty-option="true" @change="setSelectedVila" />
                <fp-custom-drop-down :value="getDropDownLabelByValue(selectedTerm, defaultData.termTypes)" 
                  :options="defaultData.termTypes" :empty-option="true" @change="setSelectedTerm" />

              </div>
            </div>

            <div class="middleSection itemWrapper">

              <div class="potentialSlider">
                <FpSlider :id="'potential'" v-model="selectedPotential" :min="0" :max="defaultData.potentials.length - 1" :format="formatValue" />
              </div>
              <div class="inputs">
                <div v-if="selectedTerm === 'shortTerm'">
                  <div class="headers ltr row">

                    <div class="col-2 season">Season</div>
                    <div class="col-5 rentalRate">Daily rental rate</div>
                    <div class="col-5 occpupancy">Occupancy </div>
                  </div>

                  <div v-for="(season, index) in defaultData.seasons" :key="index" class="row inputRow">
                    <div class="col-5">
                      <FpSlider 
                      :id="'occupancySlider' + index" 
                      v-model="defaultData.occupancies[index]" 
                      :min="50" 
                      :max="100"
                      :format="val => `${val}%`"
                       />
                    </div>
                    <div class="col-5">
                      <FpSlider 
                      :id="'dailyRenatal' + index" 
                      v-model="defaultData.dailyRental[selectedVila][index]"                         
                      :min="7000"
                      :max="23000" :step="1000"
                      :format="formatNum"
                       />
                    </div>
                    <div class="col-2 flex justify-center">
                      <label class="season">{{ season }}</label>

                    </div>
                  </div>

                </div>
                <div v-else class="monthlyRentals sliderWrapper">
                  <h5 >Monthly Rent</h5>
                  <FpSlider 
                    :id="'monthlyRenatal'" 
                    v-model="defaultData.monthlyRentals[selectedVila]" 
                    :min="100000" 
                    :max="200000"
                    :step="1000" 
                    :format="formatNum"
                    />
                  <!-- <input id="monthlyRental3Rooms" type="number" v-model="monthlyRentals[selectedVila]"> -->

                </div>

              </div>


            </div>
          </div>
          <div class="col-4">
            <div class="seasons itemWrapper ltr">

              <div class="roi">
                <div> R.O.I in x {{ roiInYears }} years</div>
                <div class="roi-percentage">
                  {{ roi }}%
                </div>
              </div>
              <SeasonsChart :selectedTerm="selectedTerm" :seasons="defaultData.seasons" :occupancies="defaultData.occupancies" :days="defaultData.days"
                :dailyRental="defaultData.dailyRental" />

            </div>
          </div>
          <div class="col-12" style="padding-top: .2rem;">
            <div class="yearly ltr itemWrapper">
              <div class="section">
                <div class="label">Yearly income</div>
                <div class="value">{{ formatNum(yearlyIncome) }};</div>
              </div>
              <div class="section">
                <div class="label">Expenses</div>
                <div class="value">{{ formatNum(totalExpenses[selectedTerm]) }};</div>
              </div>

              <div class="section profit">
                <div class="label">Expenses</div>
                <div class="value">{{ formatNum(annualProfit) }};</div>
                <!-- <div>Lease Profit: {{ formatNum(annualProfit * yearsOnLease) }}</div> -->
              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-if="showAdmin" class="col-4">
        <AdminPanel 
          @onInputChange="handleInputChange" 
          :defaultData="defaultData"
          @save="handleSaveData"
          :expensesTypes="expensesTypes"
          />
      </div>
    </div>



  </div>

</template>

<script>

import SeasonsChart from "@/components/Calculator/Seasons.vue"
import ExpensesChart from "@/components/Calculator/Expenses.vue"
import FpSlider from "@/components/FpSlider";
import FpCustomDropDown from "@/components/buttons/FpCustomDropDown";
import {formatNumber} from "@/utils"
import AdminPanel from './AdminPanel.vue'

export const YEARLY_MAINTENANCE = 'Yearly maintenance';
export const MANAGEMENT_FEES = 'Management fees';
export const FIXED = 'fixed';
export const PERCENT_OF_YEARLY_INCOME = 'percent of yearly income';
export const LOW = 'Low';
export const HIGH = 'High';
export const PEAK = 'Peak';
export const SHORT_TERM = 'shortTerm';
export const LONG_TERM = 'longTerm';



const expensesTypes = [
  FIXED, PERCENT_OF_YEARLY_INCOME
  ]
const defaultData = {
  occupancies: [60, 75, 85],
  days: [54, 90, 127],
  seasons: [LOW, HIGH, PEAK],
  
  yearsOnLease: 29,
  currencies: [
    {
      label: 'USD',
      value: 1,
      rate: 0.029
    },
    {
      label: 'THB',
      value: 2,
      rate: 1
    },
    {
      label: 'EUR',
      value: 3,
      rate: 0.025
    },
    {
      label: 'ILS',
      value: 4,
      rate: 0.1
    }
  ],
  vilaTypes: [
    {
      label: '3-Rooms',
      value: 3
    },
    {
      label: '4-Rooms',
      value: 4
    }
  ],
  termTypes: [
    {
      label: 'Short term',
      value: SHORT_TERM
    },
    {
      label: 'Long term',
      value: LONG_TERM
    }
  ],

  monthlyRentals: {
    3: 120000,
    4: 150000
  },
  dailyRental: {
    3: [12000, 14000, 16000],
    4: [14000, 16000, 18000]
  },
  initialInvestment: {
    3: 10900000,
    4: 12900000
  },
  yearsLease: 29,
  expenses: [
    {
      label: YEARLY_MAINTENANCE,
      type: FIXED,
      description: 'Yearly maintenance fees are fixed',
      vilaTypes: {
        3: {
          shortTerm: 100000,
          longTerm: 80000
        },
        4: {
          shortTerm: 120000,
          longTerm: 100000
        }
      }
    },
    {
      label: MANAGEMENT_FEES,
      type: PERCENT_OF_YEARLY_INCOME,
      description: 'Management fees are calculated as a percentage of the yearly income',
      vilaTypes: {
        3: {
          shortTerm: 0.3,
          longTerm: 0.2
        },
        4: {
          shortTerm: 0.3,
          longTerm: 0.2
        }
      }
    },
  ],
  potentials: [    
    {
      label: 'Bad',
      occupancies: [60, 70, 80],
      dailyRental: {
        3: [13000, 15000, 17000],
        4: [15000, 17000, 19000]
      }
    },
    {
      label: 'Medium',
      occupancies: [70, 80, 90],
      dailyRental: {
        3: [14000, 16000, 18000],
        4: [16000, 18000, 20000]
      }
    },
    {
      label: 'Good', 
      occupancies: [80, 90, 100],
      dailyRental: {
        3: [15000, 17000, 19000],
        4: [17000, 19000, 21000]
      }
      
    }

  ]
}
export default {
  name: 'FpCalculator',
  components: {
    SeasonsChart,
    ExpensesChart,
    FpSlider,
    FpCustomDropDown,
    AdminPanel

  },
  props: {
    initialData: {
      type: Object,
      required: true,
      // default: () => {},
    },

  },
  data() {
    return {
      selectedTerm: SHORT_TERM,
      expensesTypes,
      selectedVila: 3,
      selectedCurrency: 2,
      showAdmin: false,
      selectedPotential: 0,
      totalExpenses: {
        shortTerm: 0,
        longTerm: 0
      },
      defaultData,
      
    }
  },
  computed: {
    annualProfit() {
      return this.yearlyIncome - this.totalExpenses[this.selectedTerm]
    },
    incomePerSeason() {
      return this.defaultData.occupancies.map((occupancy, index) => {
        return occupancy * this.defaultData.days[index] * this.defaultData.dailyRental[this.selectedVila][index] / 100
      })
    },
    yearlyIncome() {
      if (this.selectedTerm === SHORT_TERM) {
        return this.incomePerSeason[0] + this.incomePerSeason[1] + this.incomePerSeason[2]
      } else {
        return this.defaultData.monthlyRentals[this.selectedVila] * 12
      }

    },
    roi() {
      return (this.annualProfit / this.defaultData.initialInvestment[this.selectedVila] * 100).toFixed(0)
    },
    roiInYears() {
      return (this.defaultData.initialInvestment[this.selectedVila] / this.annualProfit).toFixed(1)
    },
    calculatedExpenses() {
        const finalExpenses = this.defaultData.expenses.map(expense => {
          // console.log('expense[this.selectedVila]', expense.value[this.selectedVila])
          // console.log('expense[this.selectedTerm]', expense.value[this.selectedTerm])
          switch (expense.type) {
            case PERCENT_OF_YEARLY_INCOME:
              return this.yearlyIncome * expense.vilaTypes[this.selectedVila][this.selectedTerm]
       
          
            default:
              return expense.vilaTypes[this.selectedVila][this.selectedTerm];
          }
        })
        console.log("series", finalExpenses)
        return finalExpenses
    },
  },
  mounted() {
    console.log("mounted", this.defaultData);
    console.log("mounted", this.initialData);
  },
  methods: {
    handleYealyIncomeChange(newValue) {
      // React to the change in the child component's property
      console.log('handleYealyIncomeChange:', newValue);
      if (this.selectedTerm === 'Short term') {

        this.yearlyIncome.shortTerm = newValue
      } else
        this.yearlyIncome.longTerm = newValue

    },
    formatValue(value) {
      // console.log('formatValue:', value);
      // console.log('this.defaultData:', this.defaultData);
      // return value
      return `${this.defaultData.potentials[value].label}`;
    },
    // formatSliderValue(value) {
    //   return formatNum;
    // },
    handleInputChange(data) {
      console.log('handleInputChange:', data);
      Object.keys(data).forEach(key => {
        this.defaultData[key] = data[key]
      })
    },
    handleSaveData(data) {
      console.log('handleSaveData:', data);
      this.defaultData = data
    },
    handleExpensesChange(newValue) {
      // React to the change in the child component's property
      console.log('handleExpensesChange:', newValue);
      this.totalExpenses[this.selectedTerm] = newValue
      // Perform any action in response to the change
    },
    formatNum(num) {
      // console.log('formatNum', num)
      num = Math.round(num)
      let currencySymbol = ''
      let numBySelectedCurrency = this.convertCurrency(num);
      switch (this.selectedCurrency) {
        case 1:
          currencySymbol = '$';

          break;
        case 2:
          currencySymbol = '฿'
          break;
        case 3:
          currencySymbol = '€'
          break;
        case 4:
          currencySymbol = '₪'
          break;
        default:
          currencySymbol = ''
          break;
      }
      return formatNumber(numBySelectedCurrency) + currencySymbol
    },
    convertCurrency(initialNum) {
      const rate = this.defaultData.currencies.find(currency => currency.value === this.selectedCurrency).rate;
      return initialNum * rate;
    },
    getCurrencyCode(currencyValue) {
      switch (currencyValue) {
        case 1:
          return 'USD';
        case 2:
          return 'THB';
        case 3:
          return 'EUR';
        case 4:
          return 'ILS';
        default:
          return 'USD';
      }
    },
    setSelectedVila(option) {
      this.selectedVila = option.value
    },
    setSelectedCurrency(option) {
      this.selectedCurrency = option.value
    },
    setSelectedTerm(option) {
      this.selectedTerm = option.value
    },

    setPotential(name) {
      console.log(name)

    },
    getDropDownLabelByValue(value, options) {
      return options.find(option => option.value === value).label
    }
  },
  watch: {
    selectedPotential: function (newVal) {
      console.log('selectedPotential newVal', newVal)
      this.defaultData.occupancies = this.defaultData.potentials[newVal].occupancies;
      this.defaultData.dailyRental = this.defaultData.potentials[newVal].dailyRental;

    },
    selectedCurrency: function (newVal) {
      console.log('selectedCurrency newVal', newVal)
      const currentDailyRental = JSON.parse(JSON.stringify(this.defaultData.dailyRental[this.selectedVila])) ;
      const currentMonthlyRentals = this.defaultData.monthlyRentals[this.selectedVila];
      console.log('currentDailyRental', currentDailyRental)
      this.defaultData.dailyRental[this.selectedVila] = [0,0,0]
      this.defaultData.monthlyRentals[this.selectedVila] = 0
      setTimeout(() => {
        this.defaultData.dailyRental[this.selectedVila] = currentDailyRental;
        this.defaultData.monthlyRentals[this.selectedVila] = currentMonthlyRentals;
      }, 10);
      // this.defaultData.dailyRental[this.selectedVila] = currentDailyRental
    },
    initialData: {
      handler(newVal) {
        console.log('initialData changed:', newVal);
        this.defaultData = { ...newVal };
      },
      deep: true,
      immediate: true
    }
  }


}
</script>

<style lang="scss" scoped>
.wrapper {
  --blue: #61BED8;
  --peak: #00ACB3;
  --high: #47F8FF;
  --low: #5CE1E6;
  position: relative;
  margin: 4rem 6rem;

  .header {
    font-size: 4rem;

    .header-amount {
      color: var(--blue);
    }
  }

  .Peak {
    color: var(--peak);
  }

  .High {
    color: var(--high);
  }

  .Low {
    color: var(--low);
  }

}

.itemWrapper {
  border-radius: 1rem;
  margin: .2rem;
}

.selectVila {


  select {
    font-size: 1rem;
    background-color: black !important;
    color: white;
    border: none;
    margin: 1rem 3rem;
  }
}

.topSection {
  background-color: #1E1E1E;

  .selected {
    background-color: transparent !important;
    color: var(--blue) !important;
  }

  .rooms {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .7rem;
    font-size: .8rem !important;
    .dropdown {
      width: 6rem;
      height: 2rem;
      .dropdown-toggle {
        background-color: #6B6B6B;
        border-radius: .5rem;
        font-size: .8rem !important;
        border: none;
      }
    }
  }

  .dropDowns {
    direction: ltr;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    scale: 1;

    padding: 0.5rem;
    .dropdown {
      font-size: .8rem;
      height: 2rem;
    }
  }
}
.sliderWrapper {
  .slider-base {
  
    background: linear-gradient(to right, white, #61BED8);
  }
  .slider-handle {
    border: none;
    background-color: white;
    width: .4rem;
    height: .4rem;
    top: -0.2rem;
  }
  .slider-tooltip-top {
    transform: translate(-50%, -30%);
  }
}
.middleSection {
  display: flex;
  background-color: #1e1e1eb5;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  //width: 100%;
  //margin: .5rem 0rem 0rem 0rem;
  //gap: 1rem;

  .inputRow {
    margin: 1.8rem 0;
    .slider-base {
  
      background: linear-gradient(to right, white, #61BED8);
    }
    .slider-handle {
      border: none;
      background-color: white;
      width: .4rem;
      height: .4rem;
      top: -0.2rem;
    }
    .slider-tooltip-top {
      transform: translate(-50%, -30%);
    }
    .col-5 {
      padding: 0 1rem;
      padding-top: .5rem;
    }
    //margin-top: 2rem;
  }

  input {
    margin: .5rem 1rem;
  }
}

.expensis {
  background-color: #1E1E1E;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;

  .apexcharts-legend {
    display: block !important;
    position: absolute !important;
    bottom: 0 !important;
    background-color: #303030;
  }
  

}

.seasons {
  height: 100%;
  background-color: #303030;



}

.yearly {
  display: flex;
  justify-content: space-around;
  font-size: 2rem !important;

  height: 100%;
  background-color: #1E1E1E;


  padding: 1rem;

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: .5rem;
    width: 100%;

    

    .label {
      font-size: .9rem;
      border-radius: 1rem;
      background-color: #424242;
      text-align: center;
      width: 75%;

    }

    .value {
      font-size: 1.5rem;
      color: var(--blue);
      position: relative;
      text-align: center;
      width: 100%;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 1rem;
        height: 1rem;
        border-left: 1px solid white;
        border-bottom: 1px solid white;
        rotate: -135deg;
      }

    }

    &.profit {


      .label {
        background-color: #45ABC7;
      }

      .value {
        color: white;
        &::after {
          content: none;
  
        }

      }
    }
  }

}

h2 {
  text-align: center;
  border-bottom: 1px solid white;
  margin: 1rem;
  padding-bottom: .5rem;
}



.potentialSlider {
  width: 100%;
  padding: 1rem 4rem;

  .slider-handle {
    width: 4rem !important;
  }


}

.inputs {
  height: 10rem;
  width: 98%;
  margin-top: 1rem;


  h5 {
    margin-bottom: 2rem;
  }
}

.headers {
  //display: flex;
  background-color: #252525;
  //gap: 7rem;
  border-radius: .5rem;


  div {
    font-size: 0.8rem;
    width: 90%;
    //border-radius: .5rem;
    padding: 0.1rem;
    text-align: center;
  }


  .occpupancy {
    background-color: #424242;
    border-radius: .5rem;

  }

  .rentalRate {
    background-color: #343434;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}




.roi {
  padding: .5rem;
  font-size: 0.9rem;

  .roi-percentage {
    font-size: 3rem;
    margin-left: -.3rem;
  }
}





.ltr {
  direction: ltr;

}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.space-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}
</style>
<template>
  <div class="admin-container ltr">
    <div class="flex justify-between align-center mb-2 header">
      <div>Admin</div>
      <fp-custom-drop-down :value="tab" :options="tabs" @change="setSelectedItem"></fp-custom-drop-down>
      <button @click="save">Save</button>

    </div>
    <!-- <div class="selectedTab">{{ tab }}</div> -->

    <form @submit.prevent="save" :style="{
      'max-height': '80vh',
      'overflow-y': 'auto'
    }">
      <div v-if="tab === 'General'" class="generalForm">
        <div class="form-group">
          <label for="initialInvestment">Initial Investment</label>
          <div class="items">
            <div v-for="(investment, key) in formData.initialInvestment" :key="key">
              <label>{{ key }}-Rooms</label>
              <input type="number" v-model.number="formData.initialInvestment[key]" />
            </div>

          </div>
        </div>

        <div class="form-group ">
          <label for="occupancies">Occupancies</label>
          <div class="items">
            <div v-for="(occupancy, index) in formData.occupancies" :key="index">
              <label>{{ formData.seasons[index] }}</label>
              <input type="number" v-model.number="formData.occupancies[index]" />
            </div>
          </div>
        </div>

        <div class="form-group ">
          <label for="days">Days</label>
          <div class="items">
            <div v-for="(occupancy, index) in formData.days" :key="index">
              <label>{{ formData.seasons[index] }}</label>
              <input type="number" v-model.number="formData.days[index]" />
            </div>
          </div>
        </div>

        <!-- <div class="form-group">
        <label for="seasons">Seasons</label>
        <input v-for="(season, index) in formData.seasons" :key="index" type="text" v-model="formData.seasons[index]" />
      </div>

      <div class="form-group">
        <label for="potential">Potential</label>
        <input type="number" v-model.number="formData.potential" id="potential" />
      </div> -->

        <div class="form-group ">
          <label for="yearsOnLease">Years on Lease</label>
          <input type="number" v-model.number="formData.yearsOnLease" id="yearsOnLease" />
        </div>

        <div class="form-group ">
          <label for="monthlyRentals">Monthly Rentals</label>
          <div class="items">
            <div v-for="(rental, key) in formData.monthlyRentals" :key="key">
              <label>{{ key }}-Rooms</label>
              <input type="number" v-model.number="formData.monthlyRentals[key]" />
            </div>
          </div>
        </div>

        <div class="form-group ">
          <label for="dailyRental">Daily Rental</label>
          <div class="items">
            <div v-for="(rentals, key) in formData.dailyRental" :key="key">
              <div class="mb-1">{{ key }}-Rooms</div>
              <div v-for="(rental, index) in rentals" :key="index" class="flex">
                <label style="width: 2rem">{{ formData.seasons[index] }}</label>
                <input class="ml-1" type="number" v-model.number="formData.dailyRental[key][index]" />
              </div>

            </div>
          </div>
        </div>

      </div>
      <div v-if="tab == 'Expenses'">
        <button @click="addExpense">Add expense</button>
        <div v-for="(expense, key) in formData.expenses" :key="key" class="form-group expense">
          <!-- <label>{{ expense.label }}</label> -->
           <div>
              <label for="expenseName">Name: </label>
             <input name="expenseName" type="text" v-model="expense.label" class="flex"/>
             <br>
             <select v-model="expense.type" >
              <option v-for="(type, index) in expensesTypes" :key="index" :value="type">
                {{ type }}
              </option>
            </select>
            <br>
             <label for="expenseDescription">Description: </label>
             <textarea 
              name="expenseDescription" 
              id="expenseDescription"         
              rows="4"
              v-model="expense.description"></textarea>
           </div>
          <div v-for="(value, vilaType, index) in expense.vilaTypes" :key="index">
            <label>{{ formData.vilaTypes.find(type => type.value == vilaType).label }}</label>
            <div v-for="(value, term, indx) in expense.vilaTypes[vilaType]" :key="indx">
              <label>{{ term }}</label>
              <input type="number" v-model.number="expense.vilaTypes[vilaType][term]" />
            </div>
          </div>
        </div>


      </div>
      <div v-if="tab == 'Potentials'">
        <div class="flex justify-between align-center">
          <div>
            <label  for="selectedPotential">Selected potential</label>
            <select v-model="selectedPotential" id="selectedPotential">
              <option v-for="(potential, index) in formData.potentials" :key="index" :value="index">
                {{ potential.label }}
              </option>
            </select>

          </div>
          <button 
            @click="addPotential"
            :disabled="formData.potentials.some(pot => pot.label === 'New Potential')"
            >Add</button>
        </div>
        <div class="flex align-center m-1">
          <label for="potentiaName">Name: </label>
          <input name="potentiaName" type="text" v-model="formData.potentials[selectedPotential].label" />

        </div>
        <!-- <label class="porntialName">
          {{ formData.potentials[selectedPotential].label }}
        </label> -->
          <div class="form-group">
            <div class="mr-2">Occupancies</div>
            <div class="items">
              <div v-for="(occupancy, index) in formData.potentials[selectedPotential].occupancies" :key="index">
                <label>{{ formData.seasons[index] }}</label>
                <input type="number" v-model.number="formData.potentials[selectedPotential].occupancies[index]" />
              </div>
            </div>
          </div>
          <div class="form-group ">
            <label for="dailyRental">Daily Rental</label>
            <div class="items">
              <div v-for="(rentals, key) in formData.potentials[selectedPotential].dailyRental" :key="key">
                <div class="mb-1">{{ key }}-Rooms</div>
                <div v-for="(rental, index) in rentals" :key="index" class="flex">
                  <label style="width: 2rem">{{ formData.seasons[index] }}</label>
                  <input class="ml-1" type="number" v-model.number="formData.potentials[selectedPotential].dailyRental[key][index]" />
                </div>

              </div>
            </div>
          </div>
        <!-- <div v-for="(potential, key) in formData.potentials" :key="key" class="potential">
          <label class="porntialName">{{ potential.label }}</label>
          <div class="form-group">
            <div class="mr-2">Occupancies</div>
            <div class="items">
              <div v-for="(occupancy, index) in potential.occupancies" :key="index">
                <label>{{ formData.seasons[index] }}</label>
                <input type="number" v-model.number="potential.occupancies[index]" />
              </div>
            </div>
          </div>
          <div class="form-group ">
            <label for="dailyRental">Daily Rental</label>
            <div class="items">
              <div v-for="(rentals, key) in potential.dailyRental" :key="key">
                <div class="mb-1">{{ key }}-Rooms</div>
                <div v-for="(rental, index) in rentals" :key="index" class="flex">
                  <label style="width: 2rem">{{ formData.seasons[index] }}</label>
                  <input class="ml-1" type="number" v-model.number="potential.dailyRental[key][index]" />
                </div>

              </div>
            </div>
          </div>

        </div> -->


      </div>
    </form>
  </div>
</template>

<script>
import FpCustomDropDown from "@/components/buttons/FpCustomDropDown";

export default {
  name: 'AdminPanel',
  components: {
    FpCustomDropDown
  },
  data() {
    return {
      formData: {},
      tabs: ['General', 'Expenses', 'Potentials'],
      tab: 'General',
      selectedTerm: 'shortTerm',
      selectedVila: 3,
      selectedCurrency: 1,
      showAdmin: true,
      selectedPotential: 0,
    };
  },
  props: {
    defaultData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    expensesTypes: {
      type: Array,
      default: () => []
    },
    // selectedTerm: {
    //   type: String,
    //   default: 'Short term'
    // },
    // selectedVila: {
    //   type: Number,
    //   default: 3
    // },
    // selectedPotential: {
    //   type: Number,
    //   default: 0
    // },

  },
  // computed:{
  //   expensesTypes(){
  //     return this.formData.expenses.map(expense => expense.label)
  //   }
  // },
  methods: {
    save() {
      console.log('save:', this.formData);
      this.$emit('save', this.formData);
      // Add your save logic here, e.g., send the data to a server or update a store
    },
    setSelectedItem(item) {
      this.tab = item
    },
    addPotential() {
      const newPotential = {
        ...this.formData.potentials[this.selectedPotential],
        label: 'New Potential',
      }
      this.formData.potentials.push(newPotential);
      this.selectedPotential = this.formData.potentials.length - 1;
    },
    addExpense() {
      const newExpense = {
        ...this.formData.expenses[0],
        label: 'New Potential',
      }
      this.formData.expenses.push(newExpense);

    },
  },
  watch: {
    defaultData: {
      handler: function (newVal) {
        console.log('Data changed:', newVal);
        this.formData = newVal;
      },
      immediate: true,
      deep: true
    },
    // formData: {
    //   handler: function (newVal) {
    //     console.log('Data changed:', newVal);
    //     this.$emit('onInputChange', newVal);
    //   },
    //   deep: true
    // }
  }
};
</script>

<style scoped lang="scss">
.admin-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-size: 1rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dropdown {
  width: 7rem;

  ::deep(.selected) {
    background-color: transparent !important;
  }
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  z-index: 12;
  gap: 1rem;
  .items {
    display: flex;
    //grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}

.form-group>label:first-of-type {
  width: 10rem;
  //background-color: #007bff;
}

label {
  display: block;
  margin-bottom: 5px;
  //width: 7rem;

}


input {

  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}
input[type="number"] {
  width: 5rem;

}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>